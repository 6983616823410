<template>
    <div v-if="object">
        <b-form class="form">
            <b-form-group class="mt-1" label="Naslov">
                <validation-provider #default="{ errors }" name="naslov" rules="required|min:2|max:120">
                    <b-form-input v-model="object.title" name="title"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group class="mt-1" label="Opis">
                <validation-provider #default="{ errors }" name="opis" rules="min:5|max:5000">
                    <TextAreaEditor v-model="object.description"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group class="mt-1" label="Seznam točk">
                <div class="item-description-list" v-for="(item, id) in object.description_list" :key="'seznam_'+id" :class="[id === 0 ? '':'mt-2']">
                    <div class="d-flex align-items-center justify-content-between">
                        <h4>Točka {{id + 1}}</h4>
                        <h4 class="delete-from-list" @click="deleteFromList(id)"><fa icon="times"/></h4>
                    </div>
                    <b-form-group class="mt-1" label="Naslov">
                        <validation-provider #default="{ errors }" :name="'tocka ' + (id + 1) + ' naslov'" rules="required|min:2|max:120">
                            <b-form-input v-model="item.title" name="title"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="mt-1" label="Opis">
                        <validation-provider #default="{ errors }" :name="'tocka ' + (id + 1) + ' opis'" rules="required|min:5|max:1000">
                            <b-form-textarea v-model="item.description" rows="5"></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <b-button @click="addNewItem()">Dodaj novo točko v seznam</b-button>
                </div>
            </b-form-group>
                    
            <ImageSelector ref="imageSelector" :main_photo="object.main_photo" :additional_photos="object.additional_photos" :type="type" @update-main-photo="updateMainPhoto"/>
        </b-form>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import ImageSelector from '@/views/Components/ImageSelector'
    import {
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BForm
    } from 'bootstrap-vue'
    import TextAreaEditor from '@/views/Components/TextAreaEditor'
    export default {
        components: {
            ValidationProvider,
            ImageSelector,
            TextAreaEditor,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BButton,
            BForm
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            type: {
                type: String,
                dafault: 'add'
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        data() {
            return {
                required 
            }
        },
        methods:{
            addNewItem() {
                this.object.description_list.push({ title: '', description: ''})
            },
            deleteFromList(index) {
                this.object.description_list.splice(index, 1)
            },
            updateMainPhoto(photo) {
                this.object.main_photo = photo
            }
        }
    }
</script>

<style scoped>
    .item-description-list{
        border: 1px solid grey;
        padding: 10px 10px 10px 10px;
        position: relative;
        border-radius: 6px;
    }

    .delete-from-list{
        color: red;
        font-weight: 900;
        cursor: pointer;
    }
</style>