var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.object)?_c('div',[_c('b-form',{staticClass:"form"},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov","rules":"required|min:2|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"title"},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1303679993)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Opis"}},[_c('validation-provider',{attrs:{"name":"opis","rules":"min:5|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.description),callback:function ($$v) {_vm.$set(_vm.object, "description", $$v)},expression:"object.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2274496827)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Seznam točk"}},[_vm._l((_vm.object.description_list),function(item,id){return _c('div',{key:'seznam_'+id,staticClass:"item-description-list",class:[id === 0 ? '':'mt-2']},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h4',[_vm._v("Točka "+_vm._s(id + 1))]),_c('h4',{staticClass:"delete-from-list",on:{"click":function($event){return _vm.deleteFromList(id)}}},[_c('fa',{attrs:{"icon":"times"}})],1)]),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":'tocka ' + (id + 1) + ' naslov',"rules":"required|min:2|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"title"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Opis"}},[_c('validation-provider',{attrs:{"name":'tocka ' + (id + 1) + ' opis',"rules":"required|min:5|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"5"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-button',{on:{"click":function($event){return _vm.addNewItem()}}},[_vm._v("Dodaj novo točko v seznam")])],1)],2),_c('ImageSelector',{ref:"imageSelector",attrs:{"main_photo":_vm.object.main_photo,"additional_photos":_vm.object.additional_photos,"type":_vm.type},on:{"update-main-photo":_vm.updateMainPhoto}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }