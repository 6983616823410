<template>
    <b-overlay :show="showLoader">
        <b-card >
            <b-alert class="p-1 text-center" show variant="warning">Po dodajanju nove soseske, je potrebnih nekaj sekund, da se le ta prikaže. Če dodana soseska vidna, poskusite osvežiti spletno stran.</b-alert>
            <validation-observer ref="validate">
                <neighborhood-component ref="neighborhoodAdd" :model="object" type="add"/>
            </validation-observer>
            <div class="d-flex justify-content-end mt-2">
                <b-button @click="validationForm">Ustvari novo sososesko</b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import NeighborhoodComponent from './NeighborhoodComponent.vue'
    import {BButton, BCard, BAlert, BOverlay} from 'bootstrap-vue'
    import { ValidationObserver } from 'vee-validate'
    export default {
        components: { 
            NeighborhoodComponent,
            BCard,
            BButton,
            BAlert,
            BOverlay,
            ValidationObserver
        },            
        data() {
            return {
                showLoader: false,
                object: {
                    id: '',
                    title: '',
                    description: '',
                    description_list: [
                        {
                            title: '',
                            description: ''
                        }
                    ],
                    main_photo: '',
                    additional_photos: []
                }
            }
        },
        methods:{
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        if (this.object.description_list.length > 1 && this.object.description_list.length < 11) this.submitNewNeighborhood()
                        else this.$printWarning('Število dodanih točk mora biti med 2 in 10')
                    }
                })
            },
            async submitNewNeighborhood() {
                this.showLoader = true
                try {
                    const isImageUploadSuccessfull = await this.$refs.neighborhoodAdd.$refs.imageSelector.UploadImagesToAPI()
                    if (!isImageUploadSuccessfull) {
                        this.$printError('Prišlo je do napake pri nalaganju slik')
                        return
                    }
                    if (this.object.main_photo === '') {
                        this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                        return
                    }

                    await this.$http.post('/api/management/v1/neighborhoods', this.object)
                    this.$printSuccess('Soseska je dodana')
                    setTimeout(() => {
                        this.$router.push({name: 'neighborhoods-list'})
                    }, 1000)
                } catch (error) {
                    this.$printError(error.message)
                } finally {
                    this.showLoader = false
                }
            }
        }
    }
</script>

<style scoped>
    .item-description-list{
        border: 2px solid grey;
        padding: 35px 10px 10px 10px;
        position: relative;
    }

    .delete-from-list{
        color: red;
        font-weight: 900;
        font-size: 17px;
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }
</style>